<template>
    <div>
        <v-btn @click="signOut">sign out</v-btn>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions({
            logOut: 'auth/logout'
        }),
        async signOut() {
            await this.logOut();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>